import { createSelector } from 'reselect';
import { getEntriSetupComplete } from '../Entri';
export const getDomainValidationRequestStatus = state => state.domainWizard.get('domainValidationRequestStatus');
export const getDomainSubmissionStatus = state => state.domainWizard.get('domainSubmissionState');
export const getDomainSubmissionErrorList = state => state.domainWizard.get('domainSubmissionErrorList');
export const getSetupDomainUsageTypes = state => {
  return state.domainWizard.get('setupDomainUsageTypes');
};
export const getDedicatedIPSetupAccountType = state => state.domainWizard.get('dedicatedIPSetupAccountType');
export const getAddDomainsRequestStatus = state => state.domainWizard.get('addDomainsRequestStatus');
export const getDnsProviderRequestStatus = state => state.domainWizard.get('dnsProviderRequestStatus');
export const getDomainNameOrEmailAddress = state => state.domainWizard.get('inputtedDomainNameOrEmailAddress');
export const getTaskId = state => state.domainWizard.get('taskId');
export const getNewDedicatedIPRootDomain = state => state.domainWizard.get('newDedicatedIPRootDomain');
export const getDnsSetupState = state => state.domainWizard.get('dnsSetupState');
export const getDnsRecords = state => state.domainWizard.get('dnsRecords');
export const selectSpfRecords = state => state.domainWizard.get('spfRecords');
export const selectSpfRecordsCheckStatus = state => state.domainWizard.get('spfCheckStatus');
export const selectDmarcRecords = state => state.domainWizard.get('dmarcRecords');
export const selectDmacRecordsCheckStatus = state => state.domainWizard.get('dmarcCheckStatus');
export const selectIsDkimConnected = state => state.domainWizard.get('isDkimConnected');
export const getTaskDomainsRequestStatus = state => state.domainWizard.get('taskDomainsRequestStatus');
export const getVerifyTaskDomainsRequestStatus = state => state.domainWizard.get('verifyTaskDomainsRequestStatus');
export const getVerifyTaskDomainsRequestSucceeded = state => state.domainWizard.get('isVerifyTaskDomainsRequestSucceeded');
export const getDnsProvider = state => state.domainWizard.get('dnsProvider');
export const getEmailDomainForHostingSetup = state => state.domainWizard.get('emailDomainForHostingSetup');
export const getRedirectTarget = state => state.domainWizard.get('redirectTarget');
export const getNewContentHostingDomainIds = state => state.domainWizard.get('newContentHostingDomainIds');
export const getDomainReplacements = state => state.domainWizard.get('domainReplacements');
export const getSetupTaskCategory = state => state.domainWizard.get('setupTaskCategory');
export const getSubdomainNames = state => state.domainWizard.get('subdomainNames');
export const getDomainsPrimaryLanguages = state => state.domainWizard.get('primaryLanguages');
export const getFetchPrimaryLanguagesRequestStatus = state => state.domainWizard.get('fetchPrimaryLanguagesRequestStatus');
export const getDomainsSuffixes = state => state.domainWizard.get('suffixes');
export const getSampleSuffix = state => state.domainWizard.getIn(['suffixes', 'www']);
export const getSubdomainValidationStates = state => state.domainWizard.get('subdomainValidationStates');
export const getSubdomainValidationErrorLists = state => state.domainWizard.get('subdomainValidationErrorLists');
export const getProviderSupportsDomainConnect = state => state.domainWizard.getIn(['domainConnect', 'providerSupportsDomainConnect']);
export const getApexDomain = state => state.domainWizard.get('taskApexDomain');
export const getIsVerifyDomainsRequestInReattemptingMode = state => state.domainWizard.get('isVerifyDomainsRequestInReattemptingMode');
export const getBrandName = state => state.domainWizard.get('brandName');
export const getCustomError = state => state.domainWizard.get('customError');
export const getIsSetupComplete = state => state.domainWizard.get('isSetupComplete');
export const getFinalStepScreen = state => state.domainWizard.get('finalStepScreen');

// Note: use getAutomaticDnsSetupComplete combined selector to also check for Entri success
const getDidUtilizeDomainConnect = state => state.domainWizard.get('didUtilizeDomainConnect');
export const getVerificationEndingTime = state => state.domainWizard.get('verificationEndingTime');
export const getBrandToReplace = state => state.domainWizard.get('brandToReplace');
export const getReplacementBrand = state => state.domainWizard.get('replacementBrand');
export const getReplaceBrandRequestStatus = state => state.domainWizard.get('replaceBrandRequestStatus');
export const getOldDomainNames = state => state.domainWizard.get('oldDomainNames');
export const getIsConnectingReplacementBrand = state => state.domainWizard.get('isConnectingReplacementBrand');
export const getAreSuffixSelectionsPresent = createSelector([getDomainsSuffixes], suffixes => {
  return !!suffixes && !suffixes.isEmpty();
});
export const getIsBrandToReplaceHostingAnyDomains = createSelector([getOldDomainNames], oldDomainNames => {
  return !!oldDomainNames && !oldDomainNames.isEmpty();
});
export const selectUseBuiltInCdn = state => state.domainWizard.get('useBuiltInCdn');
export const getAutomaticDnsSetupComplete = createSelector([getDidUtilizeDomainConnect, getEntriSetupComplete], (didUtilizeDomainConnect, entriSetupComplete) => {
  return !!(didUtilizeDomainConnect || entriSetupComplete);
});