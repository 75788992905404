'use es6';

import Raven from 'raven-js';
import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import * as EmailSendingDomains from '../../api/domains/EmailSendingDomains';
import * as Domains from '../../api/domains/Domains';
import { selectIsUngatedForSpfAndDmarc, getPortalIsUngatedForEntriEmailSendingDomains } from '../../selectors/Auth';
import { hasAtLeastOneDnsError } from '../../selectors/domains/EmailSendingDomains';
import { setEntriConfiguration } from '../../slices/Entri';
import { getDnsProviderAttemptedAction, getDnsProviderSucceededAction, getDnsProviderFailedAction } from './shared';
import { updateFinalStepScreenAction } from './DomainWizard';
import { getAddDomainFailedNotification, getRemoveDomainSuccessNotification, getRemoveDomainFailedNotification, getTaskDomainsFetchFailedNotification } from '../../utils/domains/langUtils';
import { normalizeEmailSendingDnsRecords, normalizeDmarcRecords, normalizeSpfRecords, getSpfCheckStatusFromNormalizedSpfRecords, getDmarcCheckStatusFromNormalizedDmarcRecords } from '../../utils/domains/emailSending';
import * as ActionTypes from '../ActionTypes';
import { AddDomainWizardFinalStepScreens } from '../../Constants';
import { isEntriConfigValid } from '../../utils/entri';
export function getFetchEmailDomainsPendingAction() {
  return {
    type: ActionTypes.FETCH_EMAIL_DOMAINS_ATTEMPTED
  };
}
export function getFetchEmailDomainsSuccessAction(domains, maxNumberOfEmailSendingDomainsForPortal) {
  return {
    type: ActionTypes.FETCH_EMAIL_DOMAINS_SUCCEEDED,
    domains,
    maxNumberOfEmailSendingDomainsForPortal
  };
}
export function getFetchEmailDomainsFailedAction() {
  return {
    type: ActionTypes.FETCH_EMAIL_DOMAINS_FAILED
  };
}
export function fetchEmailDomains() {
  return dispatch => {
    dispatch(getFetchEmailDomainsPendingAction());
    EmailSendingDomains.getEmailDomains().then(domains => {
      dispatch(getFetchEmailDomainsSuccessAction(domains.get('results'), domains.get('maxNumberOfEmailSendingDomainsForPortal')));
    }, err => {
      Raven.captureException(err, {
        tags: {
          action: ActionTypes.FETCH_EMAIL_DOMAINS_FAILED
        }
      });
      dispatch(getFetchEmailDomainsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchSpfRecordsSuccessAction(requirements) {
  return {
    type: ActionTypes.FETCH_SPF_RECORDS_SUCCEEDED,
    requirements
  };
}
export function getFetchSpfRecordsFailedAction() {
  return {
    type: ActionTypes.FETCH_SPF_RECORDS_FAILED
  };
}
export function getFetchSPfRecordsAttemptedAction() {
  return {
    type: ActionTypes.FETCH_SPF_RECORDS_ATTEMPTED
  };
}
export function fetchSpfRecords() {
  return dispatch => {
    dispatch(getFetchSPfRecordsAttemptedAction());
    EmailSendingDomains.getSpfRecords().then(requirements => {
      dispatch(getFetchSpfRecordsSuccessAction(requirements));
    }, () => {
      dispatch(getFetchSpfRecordsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getFetchTaskEmailDomainAttemptedAction() {
  return {
    type: ActionTypes.FETCH_TASK_EMAIL_DOMAIN_ATTEMPTED
  };
}
export function getVerifyTaskEmailSendingDomainsFailedAction() {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_FAILED
  };
}
export function getFetchTaskEmailDomainSucceededAction({
  emailDomain,
  dnsRecords,
  spfRecords,
  dmarcRecords,
  isUngatedForSpfAndDmarc
}) {
  return {
    type: ActionTypes.FETCH_TASK_EMAIL_DOMAIN_SUCCEEDED,
    emailDomain,
    dnsRecords,
    spfRecords,
    spfCheckStatus: getSpfCheckStatusFromNormalizedSpfRecords(spfRecords),
    dmarcRecords,
    dmarcCheckStatus: getDmarcCheckStatusFromNormalizedDmarcRecords(dmarcRecords),
    isUngatedForSpfAndDmarc,
    isDkimConnected: emailDomain.getIn(['emailSendingDomainState', 'isDkimConnected'])
  };
}
export function getVerifyTaskEmailDomainAttemptedAction() {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_ATTEMPTED
  };
}
export function getVerifyTaskEmailDomainSucceededAction({
  emailDomain,
  dnsRecords,
  spfRecords,
  dmarcRecords
}) {
  return {
    type: ActionTypes.FETCH_VERIFY_TASK_EMAIL_DOMAIN_SUCCEEDED,
    emailDomain,
    dnsRecords,
    spfRecords,
    spfCheckStatus: getSpfCheckStatusFromNormalizedSpfRecords(spfRecords),
    dmarcRecords,
    dmarcCheckStatus: getDmarcCheckStatusFromNormalizedDmarcRecords(dmarcRecords),
    isDkimConnected: emailDomain.getIn(['emailSendingDomainState', 'isDkimConnected'])
  };
}
export function verifyTaskEmailDomain(domainName) {
  return dispatch => {
    dispatch(getVerifyTaskEmailDomainAttemptedAction());
    EmailSendingDomains.addEmailDomain(domainName).then(emailDomain => {
      const dnsRecords = normalizeEmailSendingDnsRecords(emailDomain.get('dkimRecords'));
      const spfRecords = normalizeSpfRecords(emailDomain.get('spfIncludes'));
      const dmarcRecords = normalizeDmarcRecords(emailDomain.get('dmarcRecords'));
      if (hasAtLeastOneDnsError(emailDomain)) {
        FloatingAlertStore.addAlert({
          message: I18n.text('settings-ui-domains.dashboard.table.EMAIL.dnsPropagationDelayAlertBody'),
          titleText: I18n.text('settings-ui-domains.dashboard.table.EMAIL.dnsPropagationDelayAlertTitle'),
          type: 'info'
        });
      }
      dispatch(getVerifyTaskEmailDomainSucceededAction({
        emailDomain,
        dnsRecords,
        spfRecords,
        dmarcRecords
      }));
    }, () => {
      dispatch(getVerifyTaskEmailSendingDomainsFailedAction());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchTaskEmailDomain(domainName) {
  return (dispatch, getState) => {
    dispatch(getFetchTaskEmailDomainAttemptedAction());
    const isUngatedForSpfAndDmarc = selectIsUngatedForSpfAndDmarc(getState());
    const isUngatedForEntriEmailSendingDomains = getPortalIsUngatedForEntriEmailSendingDomains(getState());
    const result = EmailSendingDomains.getEmailDomain(domainName).then(emailDomain => {
      const dnsRecords = normalizeEmailSendingDnsRecords(emailDomain.get('dkimRecords'));
      const spfRecords = normalizeSpfRecords(emailDomain.get('spfIncludes'));
      const dmarcRecords = normalizeDmarcRecords(emailDomain.get('dmarcRecords'));
      const immutableEntriConfig = emailDomain.get('entriConfiguration');
      if (isUngatedForEntriEmailSendingDomains && !!immutableEntriConfig) {
        const entriConfiguration = immutableEntriConfig.toJS();
        if (isEntriConfigValid(entriConfiguration)) {
          dispatch(updateFinalStepScreenAction(AddDomainWizardFinalStepScreens.ENTRI));
          dispatch(setEntriConfiguration(entriConfiguration));
        }
      }
      dispatch(getFetchTaskEmailDomainSucceededAction({
        emailDomain,
        dnsRecords,
        spfRecords,
        dmarcRecords,
        isUngatedForSpfAndDmarc
      }));
      if (domainName) {
        dispatch(getDnsProviderAttemptedAction());
        Domains.fetchDnsProvider(domainName).then(response => {
          dispatch(getDnsProviderSucceededAction(response, true));
        }, () => {
          dispatch(getDnsProviderFailedAction());
        }).catch(err => {
          throw err;
        });
      } else {
        dispatch(getDnsProviderFailedAction());
      }
    }, () => {
      dispatch(getVerifyTaskEmailSendingDomainsFailedAction());
      FloatingAlertStore.addAlert(getTaskDomainsFetchFailedNotification());
    });
    result.catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return result;
  };
}
export function getAddEmailDomainAttemptedAction() {
  return {
    type: ActionTypes.ADD_EMAIL_DOMAIN_ATTEMPTED
  };
}
export function getAddEmailDomainSucceededAction({
  emailDomain,
  dnsRecords,
  spfRecords,
  dmarcRecords
}) {
  return {
    type: ActionTypes.ADD_EMAIL_DOMAIN_SUCCEEDED,
    emailDomain,
    dnsRecords,
    spfRecords,
    spfCheckStatus: getSpfCheckStatusFromNormalizedSpfRecords(spfRecords),
    dmarcRecords,
    dmarcCheckStatus: getDmarcCheckStatusFromNormalizedDmarcRecords(dmarcRecords),
    isDkimConnected: emailDomain.getIn(['emailSendingDomainState', 'isDkimConnected'])
  };
}
export function addEmailDomain(domainName) {
  return (dispatch, getState) => {
    const isUngatedForEntriEmailSendingDomains = getPortalIsUngatedForEntriEmailSendingDomains(getState());
    dispatch(getAddEmailDomainAttemptedAction());
    EmailSendingDomains.addEmailDomain(domainName).then(emailDomain => {
      const dnsRecords = normalizeEmailSendingDnsRecords(emailDomain.get('dkimRecords'));
      const spfRecords = normalizeSpfRecords(emailDomain.get('spfIncludes'));
      const dmarcRecords = normalizeDmarcRecords(emailDomain.get('dmarcRecords'));
      const immutableEntriConfig = emailDomain.get('entriConfiguration');
      if (isUngatedForEntriEmailSendingDomains && !!immutableEntriConfig) {
        const entriConfiguration = immutableEntriConfig.toJS();
        if (isEntriConfigValid(entriConfiguration)) {
          dispatch(updateFinalStepScreenAction(AddDomainWizardFinalStepScreens.ENTRI));
          dispatch(setEntriConfiguration(entriConfiguration));
        }
      }
      dispatch(getAddEmailDomainSucceededAction({
        emailDomain,
        dnsRecords,
        spfRecords,
        dmarcRecords
      }));
    }, () => {
      FloatingAlertStore.addAlert(getAddDomainFailedNotification(undefined, 1));
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function getRemoveEmailDomainAttemptedAction() {
  return {
    type: ActionTypes.REMOVE_EMAIL_DOMAIN_ATTEMPTED
  };
}
export function getRemoveEmailDomainSucceededAction(domainName) {
  return {
    type: ActionTypes.REMOVE_EMAIL_DOMAIN_SUCCEEDED,
    domainName
  };
}
export function getRemoveEmailDomainFailedAction() {
  return {
    type: ActionTypes.REMOVE_EMAIL_DOMAIN_FAILED
  };
}
export function removeEmailDomain(domainName) {
  return dispatch => {
    dispatch(getRemoveEmailDomainAttemptedAction());
    EmailSendingDomains.deleteEmailDomain(domainName).then(() => {
      dispatch(getRemoveEmailDomainSucceededAction(domainName));
      FloatingAlertStore.addAlert(getRemoveDomainSuccessNotification(domainName));
    }, () => {
      dispatch(getRemoveEmailDomainFailedAction());
      FloatingAlertStore.addAlert(getRemoveDomainFailedNotification());
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function fetchDnsRecordsByDomain(domainName) {
  return dispatch => {
    dispatch({
      type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_ATTEMPTED
    });
    return EmailSendingDomains.getDomainDnsRecords(domainName).then(result => {
      const dkimTitle = I18n.text('settings-ui-domains.hostingSetupScreen.emailSendingDomainsTitle.dkim.title');
      const dkimRecords = normalizeEmailSendingDnsRecords(result.get('dkimRecords'), dkimTitle);
      const spfTitle = I18n.text('settings-ui-domains.hostingSetupScreen.emailSendingDomainsTitle.spf.title');
      const spfRecords = normalizeSpfRecords(result.get('spfIncludes'), spfTitle);
      const dmarcTitle = I18n.text('settings-ui-domains.hostingSetupScreen.emailSendingDomainsTitle.dmarc.title');
      const dmarcRecords = normalizeDmarcRecords(result.get('dmarcRecords'), dmarcTitle);
      const dnsRecords = dkimRecords.concat(spfRecords, dmarcRecords).toArray();
      dispatch({
        type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_SUCCEEDED,
        dnsRecords
      });
    }, () => {
      dispatch({
        type: ActionTypes.FETCH_DNS_RECORDS_BY_DOMAIN_FAILED
      });
    });
  };
}