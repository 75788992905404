import { DomainSetupTaskCategories } from '../../Constants';
export const shouldShowAsynchronousDomainConnect = ({
  useManualFlow,
  connectingDedicatedIP,
  setupTaskCategory,
  providerSupportsDomainConnect,
  providerSupportsSynchronousFlow,
  isUsingEntri
}) => !useManualFlow && !connectingDedicatedIP && setupTaskCategory !== DomainSetupTaskCategories.BRAND_REPLACE && providerSupportsDomainConnect && !providerSupportsSynchronousFlow && !isUsingEntri;
export const shouldShowSynchronousDomainConnect = ({
  useManualFlow,
  providerSupportsSynchronousFlow,
  connectingDedicatedIP,
  setupTaskCategory,
  isUsingEntri
}) => !useManualFlow && providerSupportsSynchronousFlow && !connectingDedicatedIP && setupTaskCategory !== DomainSetupTaskCategories.BRAND_REPLACE && !isUsingEntri;