import getLangLocale from 'I18n/utils/getLangLocale';
import getLang from 'I18n/utils/getLang';
// https://developers.entri.com/api-reference#entri-showentri-config
const EntriLocales = ['en', 'es', 'pt', 'pt-br', 'pt-pt', 'fr', 'it', 'de', 'nl', 'pl', 'tr', 'ja'];

// prefer getLangLocale to cover pt-br/pt-pt cases
export const getEntriLocale = () => {
  const langLocale = getLangLocale();
  const lang = getLang();
  if (EntriLocales.includes(langLocale)) {
    return langLocale;
  }
  if (EntriLocales.includes(lang)) {
    return lang;
  }
  return 'en';
};
export const DEFAULT_ENTRI_TTL_SECONDS = 300;
const getDnsRecordsArr = entriConfig => {
  if (typeof entriConfig.prefilledDomain === 'string' && Array.isArray(entriConfig.dnsRecords)) {
    return entriConfig.dnsRecords;
  }
  if (Array.isArray(entriConfig.prefilledDomain) && typeof entriConfig.dnsRecords === 'object') {
    const dnsRecordsMap = entriConfig.dnsRecords;
    return Object.values(dnsRecordsMap).flat();
  }
  return [];
};
export const getMaxEntriTtlSeconds = entriConfig => {
  const dnsRecords = getDnsRecordsArr(entriConfig);
  if (dnsRecords && dnsRecords.length) {
    return dnsRecords.reduce((maxTtl, record) => {
      const ttl = record.ttl || DEFAULT_ENTRI_TTL_SECONDS;
      return ttl > maxTtl ? ttl : maxTtl;
    }, 0);
  }
  return DEFAULT_ENTRI_TTL_SECONDS;
};
export const isEntriConfigValid = entriConfig => {
  return !!(entriConfig && entriConfig.applicationId && entriConfig.prefilledDomain && entriConfig.dnsRecords && entriConfig.token);
};