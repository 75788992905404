import { DnsProviders, DnsSetupGuideLink } from '../../Constants';
const dnsProviderMap = {
  [DnsProviders.GODADDY]: {
    name: 'GoDaddy',
    login: 'https://sso.godaddy.com/login',
    helpLink: 'https://knowledge.hubspot.com/getting-started-with-hubspot/how-to-go-live-with-hubspot-using-godaddy#8-go-to-godaddy-com-in-a-new-tab-and-log-into-your-godaddy-account',
    updateRecordsLink: `${DnsSetupGuideLink}#godaddy`
  },
  [DnsProviders.NAMECHEAP]: {
    name: 'Namecheap',
    login: 'https://www.namecheap.com/myaccount/login.aspx',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-using-namecheap',
    updateRecordsLink: `${DnsSetupGuideLink}#namecheap`
  },
  [DnsProviders.CLOUDFLARE]: {
    name: 'Cloudflare',
    login: 'https://dash.cloudflare.com/login',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-and-a-generic-cpanel-name-server',
    updateRecordsLink: `${DnsSetupGuideLink}#cloudflare`,
    requiresTrademarkAttributionStatement: true
  },
  [DnsProviders.ENOM]: {
    name: 'eNom',
    login: 'https://www.enom.com/login.aspx',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-using-enom#8-go-to-www-enom-com-in-a-new-tab-and-log-in-to-your-enom-account',
    updateRecordsLink: `${DnsSetupGuideLink}#enom`
  },
  [DnsProviders.REGISTER_DOT_COM]: {
    name: 'Register.com',
    login: 'https://www.register.com/myaccount/productdisplay.rcmx',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-using-register-com',
    updateRecordsLink: `${DnsSetupGuideLink}#register-com`
  },
  [DnsProviders.ONE_AND_ONE]: {
    name: 'IONOS',
    login: 'https://www.1and1.com/login',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-using-1and1#8-go-to-1and1-com-in-a-new-tab-and-log-into-your-1and1-account',
    updateRecordsLink: `${DnsSetupGuideLink}#1and1`
  },
  [DnsProviders.BLUEHOST]: {
    name: 'Bluehost',
    login: 'https://login.bluehost.com',
    helpLink: 'https://knowledge.hubspot.com/articles/kcs_article/cos-general/how-to-go-live-with-hubspot-using-blue-host',
    updateRecordsLink: `${DnsSetupGuideLink}#bluehost`
  },
  [DnsProviders.NETWORK_SOLUTIONS]: {
    name: 'Network Solutions',
    login: 'https://www.networksolutions.com/manage-it/index.jsp',
    helpLink: 'https://knowledge.hubspot.com/getting-started-with-hubspot/how-to-go-live-with-hubspot-using-network-solutions#8-go-to-www-networksolutions-com-in-a-new-tab-and-log-in-to-your-network-solutions-account',
    updateRecordsLink: `${DnsSetupGuideLink}#network-solutions`
  },
  [DnsProviders.GOOGLE]: {
    name: 'Google Domains',
    login: 'https://domains.google.com/registrar/',
    helpLink: 'https://knowledge.hubspot.com/domains-and-urls/connect-a-domain-to-hubspot',
    updateRecordsLink: `${DnsSetupGuideLink}#google-domains`
  }
};
function isKnownDnsProviderKey(key) {
  return key in DnsProviders;
}
export function getDnsProviderInfo(dnsProvider) {
  if (isKnownDnsProviderKey(dnsProvider)) {
    return dnsProviderMap[dnsProvider];
  }
  return null;
}
export function getDnsProviderName(dnsProvider) {
  const dnsProviderInfo = getDnsProviderInfo(dnsProvider);
  if (!dnsProviderInfo) {
    return '';
  }
  return dnsProviderInfo.name;
}